<template>
<div class="app-container">
  <el-tabs v-model="tab">
      <el-tab-pane name="series" label="系列">
        <series/>     
      </el-tab-pane>
      <el-tab-pane name="spu" label="产品SPU" >
        <spu/>
      </el-tab-pane>
      <el-tab-pane name="sku" label="产品SKU" >
        <sku/>
      </el-tab-pane>
    </el-tabs>
  
    
  </div>
</template>

<script>

import Series from "./details/series";
import Spu from "./details/spu";
import Sku from "./details/sku";

export default {
  components: { Series, Spu, Sku },
  data() {
    return {
      tab: "series",
    };
  },  
};
</script>