<template>
<div class="app-container flex v">
        <div class="head-container">
          <el-input v-model="query.disName" clearable placeholder="经销商搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />      
          <el-input v-model="query.name" clearable placeholder="系列搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />    
          <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
          <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
          <!-- </div> -->
          <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
        </div>

        <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
          <el-table-column prop="disCode" label="经销商编码" width="180" fixed />
          <el-table-column prop="disName" label="经销商" min_width="200" show-overflow-tooltip />  
          <el-table-column prop="seriesName" label="系列" mint-width="150" />
          <el-table-column prop="begAt" label="开始时间" width="200" :formatter="v=>{return v.begAt?new Date(v.begAt).format():''}"/>
          <el-table-column prop="endAt" label="结束时间" width="200" :formatter="v=>{return v.endAt?new Date(v.endAt).format():''}"/>
          
        </el-table>
        <!--分页组件-->
        <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />        

  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      tab: "series",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributorGoods/series";
      //const sort = "id,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size },
        this.query
      );
      return true;
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      this.params.size = this.total;
      download("api/distributorGoods/series/download", this.params)
        .then((result) => {
          downloadFile(result, "经销商授权系列信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  
  },
};
</script>